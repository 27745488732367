import React from "react"

import { Phone, Terminal, Gift } from "./images"

import type { BenefitsItem } from "./types"

const hintClassNames = {
  c1: {
    xs: {
      top: -8,
      left: -175,
      backgroundColor: "#edf6fe",
      width: "280px",
    },
    sm: {
      top: -8,
      left: -185,
      width: "309px",
    },
    md: {
      left: -225,
    },
    lg: {},
  },

  c2: {
    xs: {
      top: -8,
      left: -210,
      backgroundColor: "#edf6fe",
      width: "280px",
    },
    sm: {
      top: -8,
      left: -205,
      width: "240px",
    },
    md: {
      top: -8,
      left: -75,
      width: "309px",
    },

    lg: {
      left: -214,
    },
  },

  c3: {
    xs: {
      top: -8,
      left: -243,
      backgroundColor: "#edf6fe",
      width: "280px",
    },
    sm: {
      top: 0,
      left: -83,
      width: "240px",
    },
    md: {
      top: -8,
      left: -76,
      width: "309px",
    },
    lg: {
      top: -8,
      left: -248,
      width: "309px",
    },
  },
}

export const ITEMS: BenefitsItem[] = [
  {
    Img: Phone,
    alt: "ten months",
    title: "Как обычная карта",
    tooltipHint:
      "Стикер – дополнительная карта к основной Халве, с ним вы можете получать все бонусы подписки «Халва.Десятка».",
    hintClassName: hintClassNames.c1,
  },
  {
    Img: Terminal,
    alt: "twelve percents",
    title: "Замена NFC на iPhone",
    tooltipHint: "Замена NFC для устройств на iOS и возможность расплачиваться телефоном без NFC",
    hintClassName: hintClassNames.c2,
  },
  {
    Img: Gift,
    alt: "cashback",
    title: <>Все&nbsp;преимущества Халвы</>,
    tooltipHint: (
      <>
        — Обслуживание 0 ₽
        <br />
        — Рассрочка до 24 месяцев
        <br />— До 10% кэшбэк
      </>
    ),
    hintClassName: hintClassNames.c3,
  },
]
